window.addEventListener("load", (event) => {
    const headerNav = document.querySelector('.header-nav');
    document.querySelectorAll('.cg-main-draggable').forEach(element => {
        dragElement(element);

        function dragElement(element) {
            var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0, offsetX = 0, offsetY = 0;
            element.onmousedown = dragMouseDown;
            element.ontouchstart = dragTouchStart;

            function dragMouseDown(e) {
                e = e || window.event;
                e.preventDefault();
                // get the mouse cursor position at startup:
                pos3 = e.clientX;
                pos4 = e.clientY;
                // calculate the cursor offset within the element:
                offsetX = pos3 - element.getBoundingClientRect().left;
                offsetY = pos4 - element.getBoundingClientRect().top;
                document.onmouseup = closeDragElement;
                // call a function whenever the cursor moves:
                document.onmousemove = elementDrag;
            }

            function dragTouchStart(e) {
                e = e || window.event;
                e.preventDefault();
                var touch = e.touches[0];
                // get the touch position at startup:
                pos3 = touch.clientX;
                pos4 = touch.clientY;
                // calculate the cursor offset within the element:
                offsetX = pos3 - element.getBoundingClientRect().left;
                offsetY = pos4 - element.getBoundingClientRect().top;
                document.ontouchend = closeDragElement;
                // call a function whenever the cursor moves:
                document.ontouchmove = elementTouchDrag;
            }

            function elementDrag(e) {
                e = e || window.event;
                e.preventDefault();
                // calculate the new cursor position:
                pos1 = pos3 - e.clientX;
                pos2 = pos4 - e.clientY;
                pos3 = e.clientX;
                pos4 = e.clientY;
                // set the element's new position:
                element.style.top = (pos4 - offsetY) + "px";
                element.style.left = (pos3 - offsetX) + "px";
            }

            function elementTouchDrag(e) {
                e = e || window.event;
                e.preventDefault();
                var touch = e.touches[0];
                // calculate the new cursor position:
                pos1 = pos3 - touch.clientX;
                pos2 = pos4 - touch.clientY;
                pos3 = touch.clientX;
                pos4 = touch.clientY;
                // set the element's new position:
                element.style.top = (pos4 - offsetY) + "px";
                element.style.left = (pos3 - offsetX) + "px";
            }

            function closeDragElement() {
                // stop moving when mouse button is released:
                document.onmouseup = null;
                document.onmousemove = null;
                document.ontouchend = null;
                document.ontouchmove = null;
            }
        }
    });
});
